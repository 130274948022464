import { useDispatch } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { logout } from 'redux/actions/authActions';
import { useNavigate } from 'react-router-dom';

export const useAccountLogout = () => {
  const dispatch = useDispatch();
  //const navigate = useNavigate();

  const logoutUser = useCallback(() => {
    dispatch(logout());
    localStorage.removeItem("user");
    //navigate('/authentication/sign-in');
  }, []);

  return logoutUser;

};
