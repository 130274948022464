// store/index.js
import { createStore, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import authReducer from './reducers/authReducer';
import settingReducer from './reducers/settingReducer';
import quizReducer from './reducers/quizReducer';
import loaderReducer from './reducers/loaderReducer';

// Load user data from localStorage
const storedUser = localStorage.getItem('user');
const globalSettings = localStorage.getItem('global_setting');

const initialState = {
  auth: {
    isAuthenticated: Boolean(storedUser),
    user: storedUser ? JSON.parse(storedUser) : null,
  },
  setting: {
    setting: globalSettings ? JSON.parse(globalSettings) : null,
  }
};

const rootReducer = combineReducers({
  auth: authReducer,
  setting: settingReducer,
  quiz: quizReducer,
  loader: loaderReducer,

});

const store = createStore(rootReducer, initialState, composeWithDevTools());

export default store;
