import { BrowserRouter as Router, Routes } from 'react-router-dom';
import { Suspense, useEffect } from 'react';
import 'simplebar/dist/simplebar.min.css';
import 'tippy.js/animations/scale.css';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from 'react-redux';
import { settings } from 'redux/actions/appSettings';
import PageTitleDescription from 'components/elements/site-headers/PageTitleDescription';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import store from 'redux/store';
import LoaderFallback from 'components/elements/LoaderFallback';
import socket from 'helper/socket';
import ScrollToTop from 'layouts/dashboard/ScrollToTop';
import AllRoutes from 'layouts/AllRoutes';
import { useFetchData } from 'hooks/useFetchData';

const queryClient = new QueryClient();

function AppContent() {
	const dispatch = useDispatch();
	const { data, refreshData } = useFetchData("/settings/public");

	useEffect(() => {
		if (data?.data) {
			dispatch(settings(data?.data));
		}
	}, [data, dispatch]);

	useEffect(() => {
		const connectWebSocket = () => {
			socket.on('connect', () => {
				console.log('Connected to WebSocket server');
			});
			// Listen for the otpVerified event
			socket.on('settingsUpdated', (message) => {
				console.log(`settingsUpdated event received `, message);
				refreshData();
			});

			socket.on('connect_error', (err) => {
				console.error('Connection error: ', err.message);
			});

			socket.on('disconnect', () => {
				console.log('Disconnected from WebSocket server');
			});
		};

		connectWebSocket();

		return () => {
			socket.off('settingsUpdated');
			socket.off('connect');
			socket.off('disconnect');
			socket.off('connect_error');
		};
	}, []);

	return (
		<Router>
			<PageTitleDescription />
			<ScrollToTop />
			<Suspense fallback={<LoaderFallback />}>
				<AllRoutes />
			</Suspense>
			<ToastContainer />
		</Router>
	);
}

function App() {
	return (
		<HelmetProvider>
			<Provider store={store}>
				<QueryClientProvider client={queryClient}>
					<AppContent />
				</QueryClientProvider>
			</Provider>
		</HelmetProvider>
	);
}

export default App;
