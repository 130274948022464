import React from 'react';
import { css } from '@emotion/react';
import { PuffLoader } from 'react-spinners';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;


const LoaderFallback = () => {
    return (
      <div style={styles.loaderContainer}>
        <PuffLoader color="#36d7b7" loading={true} css={override} size={60} />
      </div>
    );
};

const styles = {
    loaderContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      width: '100%',
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      position: 'fixed',
      top: 0,
      left: 0,
      zIndex: 1000,
    },
  };

export default LoaderFallback