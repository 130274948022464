
const initialState = {
    timers: {},
    quizData: {},
    userAnswers: {},
  };
  
  const quizReducer = (state = initialState, action) => {
  
    switch (action.type) {

      case 'UPDATE_USER_ANSWER':
        const { id: questionId, selectedAnswer } = action.payload;
        return {
          ...state,
          userAnswers: {
            ...state.userAnswers,
            [questionId]: selectedAnswer,
          },
        };

      case 'UPDATE_TIMER':
        const { id, timer } = action.payload;
        return {
          ...state,
          timers: {
            ...state.timers,
            [id]: timer,
          },
        };

      case 'ADD_QUIZ':
        // Check if the user profile was updated
        return {
          ...state,
          quizData: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default quizReducer;
  